import Vue from "vue";
import $store from "@/vuex/index.js";
import { SUCCESS_CODE } from "@/apis/notification.js";
import { cloneDeep, sum, filter, pick, get } from "lodash-es";
import { apis } from "@/apis";
var base64 = require("js-base64");
export default {
  getSelection: async function (event) {
    let options = [];
    if (event.data.textField == 1) {
      options = [
        {
          label: "11",
          value: 11
        }
      ];
    }
    if (event.data.textField == 2) {
      options = [
        {
          label: "11",
          value: 11
        },
        {
          label: "22",
          value: 22
        }
      ];
    }
    // let ret = apis.getdata({ params: { id: inputKey } });
    return options;
  },
  mapVariables(data) {
    let ret = {};
    // let getType = function(data) {
    //   let type = Object.prototype.toString.call(data).match(/\s+([a-zA-Z]+)]/)[1];
    //   return type;
    // };
    // String, Number, Boolean, Array or Objec
    Object.keys(data).forEach(key => {
      // let type = getType(data[key]);
      // var value = data[key];
      // if (type === "Array" || type == "Object") {
      //   value = JSON.stringify(value);
      // }
      ret[key] = {
        value: data[key],
        type: null
      };
    });

    return ret;
  },
  async getAuthedApendixUrl(urls) {
    let ret = await apis.getAppendixFilePath({ params: { urls } });
    if (ret.code !== SUCCESS_CODE) return;
    return ret.data;
  },
  //处理开启流程、下一步
  async handleSubmit({ processKey, taskId, businessKey }, submission) {
    let token = localStorage.getItem("keyCloakToken");
    let userInfo = JSON.parse(base64.decode(token.split(".")[1]));
    if (taskId) {
      await apis.claimTaskByUserId({ placeholder: { id: taskId }, params: { userId: userInfo.userId } });
      return await this.completeTask(taskId, submission);
    } else if (processKey) {
      submission.initiator = userInfo.preferred_username;
      // 当前操作人的id 用于撤回流程
      // submission.hanlderId = userInfo.uid;

      let keyName = submission.query.renderFormKey.split(",")[0];
      submission.type = await this.getFormNameByKey(keyName);
      return await this.startProcess({ processKey, businessKey }, submission);
    } else {
      $store.commit("form/warning", "缺少taskId或processKey");
      return;
    }
  },
  async addRenderFormKeys(submission, variables, formKey) {
    let renderFormKeys = variables.query.renderFormKey.split(",");
    let idx = renderFormKeys.findIndex(item => {
      return item == formKey
    });
    if (idx != -1) return;

    submission.query = {
      ...variables.query,
      renderFormKey: variables.query.renderFormKey + ',' + formKey
    };
  },
  async getProcessInstanceVaribles(processInstanceId) {
    if (!processInstanceId) return {};
    let ret = await apis.getProcessInstanceVaribles({ placeholder: { id: processInstanceId } });
    if (ret.code != SUCCESS_CODE) return {};
    let data = {};
    Object.keys(ret.data).forEach(item => {
      data[item] = ret.data[item].value;
    });

    return data;
  },
  async getHistoryProcessInstanceVaribles(processInstanceId) {
    if (!processInstanceId) return {};
    let ret = await apis.getHistoryProcessInstanceVaribles({ params: { processInstanceId } });
    if (ret.code != SUCCESS_CODE) return {};
    let data = {};
    ret.data.forEach(item => {
      data[item.name] = item.value;
    });

    return data;
  },
  async completeTask(taskId, data) {
    let ret = await apis.completeTask({
      params: {
        variables: this.mapVariables(data),
        withVariablesInReturn: true
      },
      placeholder: {
        taskId: taskId
      }
    });
    if (ret.code != SUCCESS_CODE) {
      $store.commit("form/warning", ret.message || "审批任务新建失败");
      return false;
    }
    return true;
  },
  async startProcess({ processKey, businessKey }, submission) {
    if (!businessKey) return $store.commit("form/warning", "缺少businessKey");

    let params = {
      variables: this.mapVariables(submission),
      businessKey: `${businessKey}`
    };
    let ret = await apis.startProcessInstance({
      params: params,
      placeholder: {
        key: processKey
      }
    });
    if (ret.code != SUCCESS_CODE) {
      $store.commit("form/warning", ret.message || "审批任务新建失败");
      return false;
    }
    return true;
  },
  refresh(form, settings) {
    form.setForm(settings);
    form.checkData();
    form.redraw();
  },
  navigateBack(context) {
    setTimeout(() => {
      // 如果存在返回参数
      let from = context.$route.query.from;
      let isFromPc = from == "pc";
      if (isFromPc) {
        window.parent.postMessage({ msg: "close-current-tab", origin: "/bpmn" }, "*");
      } else {
        console.log("history back called!");
        window.history.go(-1);
      }
    }, 2000);
  },
  setAttachmentOptions(settings) {
    let attachments = this.getComponentByKey(settings, "attachments");
    attachments.url = window.process.env.VUE_APP_FILE_HOST + "/appendixes/bpmn";
    attachments.options = JSON.stringify({
      Authorization: "Bearer " + window.$keycloak.token || ""
    });
  },
  base64ToBinaryImage(base64Str) {
    const arr = base64Str.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    const suffix = mime.split('/')[1]
    const file = new File([u8arr], `signature.${suffix}`, {
      type: mime
    });

    return file;
  },
  setSignature(settings, options) {
    let signature = this.getComponentByKey(settings, "signature");
    if (!signature) return console.warn("没有找到签名上传的组件");
    signature.desktopDisplay = options.fromPc;
    signature.disabled = options.disabled || false;
    signature.uploadFile = async (base64) => {
      let fd = new FormData();
      let blob = this.base64ToBinaryImage(base64);
      fd.append("file", blob);
      fd.append("name", blob.name);
      let ret = await apis.postAppendixBpmnFile({ params: fd });
      if (ret.code != "COMMON.SUCCESS") return '';
      let url = await this.getAuthedApendixUrl(ret.data.url);
      return url[0];
    }
  },
  setDateDisabled(settings) {
    ['lendingStartDate', 'lendingEndDate'].map(item => {
      return this.getComponentByKey(settings, item)
    }).filter(item => {
      return item;
    }).forEach(item => {
      if (item.datePicker && item.datePicker.disableFunction) {
        item.datePicker.disableFunction = undefined;
      }
      if (item.disableFunction) {
        item.datePicker.disableFunction = undefined;
      }
    })
  },
  setApplyUser(settings, value) {
    let applyFrom = this.getComponentByKey(settings, "applyFrom");
    applyFrom.html = value;
  },
  async getFormNameByKey(key) {
    let ret = await apis.getFormList();
    if (ret.code != SUCCESS_CODE) return;
    let target = ret.data.find(item => {
      return item.name == key;
    });
    return target && target.description;
  },
  resetAttachmentLinkListener() {
    setTimeout(() => {
      let links = document.querySelectorAll("a");
      let downloadLinks = filter(links, item => {
        return item.getAttribute("ref") == "fileLink";
      });

      downloadLinks.forEach(async item => {
        let dataHref = item.getAttribute("data-href");
        if (dataHref) return;
        let href = item.getAttribute("href");
        if (href.includes("Expires")) return;
        let authHref = await this.getAuthedApendixUrl(href);
        item.setAttribute("href", authHref);
        item.setAttribute("data-href", href);

        // 替换原来的节点，重置原属性的事件
        item.outerHTML = item.outerHTML;
      });
    });
  },
  isReadOnlyMode(readOnly, parentClass) {
    if (!readOnly) return;

    setTimeout(function () {
      [".formio-component-submit button", ".formio-component-btnAdd"].forEach(function (key) {
        let elements = document.querySelectorAll(`${parentClass || ""} ` + key);
        elements.length && elements.forEach(ele => {
          ele.setAttribute("style", "display:none;");
        });
      });
    }, 1000);
  },
  async tansformImgToBase64(nodeList) {
    function imgToBase64(url, outputFormat) {
      if (url.startsWith("data:")) return Promise.resolve(url);

      return new Promise((resolve, reject) => {
        let canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.setAttribute('crossOrigin', 'Anonymous');
        // 解决Canvas.toDataURL 图片跨域题
        img.onload = (res) => {
          let width = img.width;
          let height = img.height;

          ctx.fillStyle = "#000000";
          ctx.fillRect(0, 0, width, height);

          // 压缩比例 -- 可以自己修改参数。500px宽度以下原尺寸，大于500px比例处理
          let bili = Math.round(width / 500) || 1;
          let rate = 1 / bili;
          canvas.width = width * rate;
          canvas.height = height * rate;
          ctx.drawImage(img, 0, 0, width, height, 0, 0, width * rate, height * rate);
          let dataURL = canvas.toDataURL(outputFormat || 'image/png');
          // 去除标头 -- 传递给后台时一般去除头部
          // let reg = new RegExp('^data:image/[^;]+;base64,');
          // dataURL = dataURL.replace(reg, '');
          canvas = null;
          resolve(dataURL);
        }
        img.src = url;
      })
    };

    // 调用：
    for (let ele of nodeList) {
      let base64 = await imgToBase64(ele.src);
      ele.src = base64;
    }
  },
  getComponentByKey(settings, key) {
    let loop = function (components, key) {
      let value = null;

      for (let item of components) {
        // console.log(item.key);
        if (item.key == key) {
          value = item;
        } else if (item.columns || item.components || item.rows) {
          let columnRows = (Array.isArray(item.rows) && item.rows) || "";
          let ret = loop(item.columns || item.components || columnRows || [], key);
          if (ret) {
            value = ret;
            return value;
          }
        } else if (Array.isArray(item)) {
          let ret = loop(item, key);
          if (ret) {
            value = ret;
            return value;
          }
        }
      }

      return value;
    };

    return loop(settings.components, key);
  }
};
